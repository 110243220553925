function grattisAjax (method, url, cb) {
  var xmlhttp = new XMLHttpRequest()
  xmlhttp.onreadystatechange = function () {
    if (xmlhttp.readyState !== 4) return
    if (xmlhttp.status === 200) {
      var data = null
      if (xmlhttp.responseText) {
        try {
          data = JSON.parse(xmlhttp.responseText)
        } catch (err) {
          return cb(err)
        }
      }
      cb(null, data)
    } else {
      cb(new Error(xmlhttp.status))
    }
  }
  xmlhttp.open(method, url, true)
  xmlhttp.send()
}

window.grattisRequest = {
  get: function (url, cb) {
    grattisAjax('GET', url, cb)
  }
};

function grattisClearTitle (title) {
  title = title.replace('Ё', 'Е').replace('ё', 'е').replace(/[^А-Яа-я\d\s]+/gi, '')
  console.log(title)
  return title
}

var grattisBase64 = {
  alphabet: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
  ie: /MSIE /.test(navigator.userAgent),
  encode: function (s) {
    /* jshint bitwise:false */
    var buffer = grattisBase64.toUtf8(s),
      position = -1,
      result,
      len = buffer.length,
      nan0,
      nan1,
      nan2,
      enc = [, , ,];

    if (grattisBase64.ie) {
      result = [];
      while (++position < len) {
        nan0 = buffer[position];
        nan1 = buffer[++position];
        enc[0] = nan0 >> 2;
        enc[1] = ((nan0 & 3) << 4) | (nan1 >> 4);
        if (isNaN(nan1)) enc[2] = enc[3] = 64;
        else {
          nan2 = buffer[++position];
          enc[2] = ((nan1 & 15) << 2) | (nan2 >> 6);
          enc[3] = isNaN(nan2) ? 64 : nan2 & 63;
        }
        result.push(
          grattisBase64.alphabet.charAt(enc[0]),
          grattisBase64.alphabet.charAt(enc[1]),
          grattisBase64.alphabet.charAt(enc[2]),
          grattisBase64.alphabet.charAt(enc[3])
        );
      }
      return result.join("");
    } else {
      result = "";
      while (++position < len) {
        nan0 = buffer[position];
        nan1 = buffer[++position];
        enc[0] = nan0 >> 2;
        enc[1] = ((nan0 & 3) << 4) | (nan1 >> 4);
        if (isNaN(nan1)) enc[2] = enc[3] = 64;
        else {
          nan2 = buffer[++position];
          enc[2] = ((nan1 & 15) << 2) | (nan2 >> 6);
          enc[3] = isNaN(nan2) ? 64 : nan2 & 63;
        }
        result +=
          grattisBase64.alphabet[enc[0]] +
          grattisBase64.alphabet[enc[1]] +
          grattisBase64.alphabet[enc[2]] +
          grattisBase64.alphabet[enc[3]];
      }
      return result;
    }
  },
  toUtf8: function (s) {
    /* jshint bitwise:false */
    var position = -1,
      len = s.length,
      chr,
      buffer = [];
    if (/^[\x00-\x7f]*$/.test(s))
      while (++position < len) buffer.push(s.charCodeAt(position));
    else
      while (++position < len) {
        chr = s.charCodeAt(position);
        if (chr < 128) buffer.push(chr);
        else if (chr < 2048) buffer.push((chr >> 6) | 192, (chr & 63) | 128);
        else
          buffer.push(
            (chr >> 12) | 224,
            ((chr >> 6) & 63) | 128,
            (chr & 63) | 128
          );
      }
    return buffer;
  }
};
(function (window, document, key, host, cdn) {

  if (window[key].length === 0) {
    return null;
  }

  var cnst = {
    KEY: 'gscp',
    MESSAGE_SET_HEIGHT: 1
  }

  window.addEventListener('message', function (event) {
    var data = event.data

    if (!data || !data.type) {
      return
    }

    switch (data.type) {
      case cnst.MESSAGE_SET_HEIGHT: {
        var id = data.id
        var height = data.ch

        var iframe = document.getElementById(cnst.KEY + '_' + id)
        if (!iframe) return
        iframe.style['height'] = height + 'px'

        var div = document.getElementsByClassName(cnst.KEY + '_' + id)[0]
        if (!div) return
        div.style['height'] = (height + 10) + 'px'
        div.style['display'] = 'block'
      }
        break;
    }
  }, false);

  var id = window[key].shift()
  var items = document.getElementsByClassName(cnst.KEY + '_' + id) || []
  for (var i = 0; i < items.length; i++) {
    var item = items[i]
    if (item.innerHTML === '') {
      (function (item) {
        item.style['display'] = 'none'
        item.style['transition'] = 'height .3s'
        item.style['position'] = 'relative'
        item.style['overflow'] = 'hidden'
        item.style['margin'] = '0'
        item.style['padding'] = '0'
        item.style['text-align'] = 'center'
        item.style['height'] = '100%'
        item.style['width'] = '100%'

        grattisRequest.get('//' + host + '/api/ShowcasePlayers/' + id + '/cache', function (err, data) {
          if (err) {
            console.log(err)
            return
          }
          // получаем текущий url (без параметров)
          function getCurrentPageUrl () {
            var location = window.location
            return encodeURIComponent(location.protocol + '//' + location.host + location.pathname)
          }

          function getCurrentPageTitle () {
            var pageTitle = document.getElementsByTagName('h1')
            if (pageTitle.length > 0) {
              pageTitle = pageTitle[0].innerText
            } else {
              pageTitle = document.title
            }
            
            pageTitle = grattisClearTitle(pageTitle)
    
            return encodeURIComponent(grattisBase64.encode(pageTitle))
          }

          var url = '//' + cdn + '/view/' + id +
            '?v=' + data.v + '|' + data.g +
            '&u=' + getCurrentPageUrl() +
            '&t=' + getCurrentPageTitle()

          var iframe = document.createElement('iframe')
          iframe.style['height'] = window.screen.height + 'px'
          iframe.style['border'] = '0'
          iframe.style['width'] = '1px'
          iframe.style['min-width'] = '100%'
          iframe.style['*width'] = '100%'
          iframe.setAttribute('id', cnst.KEY + '_' + id)
          iframe.setAttribute('align', 'middle')
          iframe.setAttribute('frameborder', 'no')
          iframe.setAttribute('scrolling', 'no')
          iframe.setAttribute('allowfullscreen', '')
          iframe.setAttribute('allowtransparency', '')
          iframe.setAttribute('src', url)
          item.appendChild(iframe)
        })
      })(item)
    }
  }
})(this, this.document, 'grattisShowcasePlayers', 'showcase-players.grattis.ru', 'cdn-showcase-players.grattis.ru');
